/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({
  description,
  lang,
  meta,
  title,
  pathname,
  featuredImagePath,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const conditionalMeta = [];
  if (featuredImagePath && featuredImagePath !== "none") {
    conditionalMeta.push({
      property: `og:image`,
      content: `${site.siteMetadata.siteUrl}${featuredImagePath}`,
    });
    conditionalMeta.push({
      name: `twitter:image`,
      content: `${site.siteMetadata.siteUrl}${featuredImagePath}`,
    });
    conditionalMeta.push({
      name: `twitter:card`,
      content: "summary_large_image",
    })
  }

  // canonical URL used for social sharing and other indexes
  if (pathname) {
    conditionalMeta.push({
      property: `og:url`,
      content: `${site.siteMetadata.siteUrl}${pathname}`,
    });
  }

  const combinedMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    ...conditionalMeta,
    ...meta,
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s // ${site.siteMetadata.title}`}
      meta={combinedMeta}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
