import Typography from "typography";

const typography = new Typography({
  baseFontSize: "18px",
  scaleRatio: 2,
  headerFontFamily: ["Arvo", "sans-serif"],
  bodyFontFamily: ["Domine", "serif"],
  googleFonts: [],
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
