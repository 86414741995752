import React from "react";
import { Link } from "gatsby";
import { rhythm } from "../utils/typography";

import headerBanner from "../../content/assets/lemon-banner.jpg";

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;

  let header;

  if (isRootPath) {
    header = (
      <>
        <h1>
          <Link
            style={{
              boxShadow: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
        <div className="frontpage-social-link">
          <a
            href="https://www.instagram.com/gourmet.quarantine/"
            target="_blank"
            rel="noreferrer"
            className="ig-link"
            title="gourmet.quarantine on Instagram"
          >
            gourmet.quarantine
          </a>
        </div>
      </>
    );
  } else {
    header = (
      <h3
        style={{
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    );
  }

  return (
    <>
      {isRootPath && (
        <>
          <header
            className="frontpage-header"
            style={{
              backgroundImage: `url(${headerBanner})`,
            }}
          >
            {header}
          </header>
        </>
      )}
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${isRootPath ? 0 : rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        {!isRootPath && <header>{header}</header>}
        <main>{children}</main>
        <footer>
          © 2020 &mdash; {new Date().getFullYear()} Gourmet Quarantine
        </footer>
      </div>
    </>
  );
};

export default Layout;
